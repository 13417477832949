<template lang="pug">
    .methods
        .item(
            v-for="item in methodsData",
            :key="item.icon"
        )
            ui-paragraph.title(v-if="item.title !== null" v-html="item.title")
            img(
                :src="require(`../../../../../assets/images/articles/4/methods/${item.icon}.svg`)",
                :alt="item.title !== null ? item.title : `Иконка-${item.icon}`",
                :class="{ 'wide': item.title === null }"
            )
            .bullets(v-if="Array.isArray(item.bullets) && item.bullets.length > 0")
                .bullet(
                    v-for="bullet in item.bullets",
                    :key="bullet.text"
                )
                    .icon-wrap
                        article-icon.icon(src="hex")
                    ui-paragraph.text(:class="[bullet.variant]")
                        span(v-html="bullet.text")
            ui-paragraph.text(v-html="item.text")
</template>

<script>
import UiParagraph from '../../../../ui/Paragraph.vue';
import ArticleIcon from '../../../Icon.vue';

export default {
    name: 'methods',
    components: {
        UiParagraph,
        ArticleIcon,
    },
    computed: {
        methodsData() {
            /* eslint-disable max-len */
            return [
                {
                    title: 'Диагностика изменений в&nbsp;органах и&nbsp;тканях',
                    icon: '1',
                    bullets: [
                        {
                            type: 'text',
                            text: 'Опрос пациента',
                        },
                        {
                            type: 'text',
                            text: 'Физикальное обследование',
                        },
                        {
                            type: 'text',
                            text: 'Функциональные исследования',
                        },
                        {
                            type: 'text',
                            text: 'Лучевое обследование',
                        },
                        {
                            type: 'text',
                            text: 'Морфологическое обследование',
                        },
                    ],
                },
                {
                    title: null,
                    icon: '2',
                    bullets: null,
                },
                {
                    title: 'Выявление возбудителя туберкулеза',
                    icon: '3',
                    bullets: [
                        {
                            type: 'text',
                            text: 'Бактериоскопическая диагностика',
                        },
                        {
                            type: 'text',
                            text: 'Культуральный метод',
                        },
                        {
                            type: 'text',
                            text: '<nobr>Молекулярно-генетическая<nobr> диагностика (ПЦР)',
                        },
                    ],
                },
                {
                    title: 'Иммунодиагностика',
                    icon: '4',
                    bullets: [
                        {
                            type: 'text',
                            text: 'Кожные пробы',
                        },
                        {
                            type: 'text',
                            variant: 'dark',
                            text: 'Лабораторные тесты на&nbsp;высвобождение <nobr>гамма-интерферона</nobr>',
                        },
                    ],
                },
            ];
            /* eslint-enable max-len */
        },
    },
};
</script>

<style lang="scss" scoped>
.methods {
    width: 100%;
    max-width: 750px;
    max-height: rem(900);
    margin: rem(45) auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: flex-start;

    .item {
        width: 50%;
        padding: rem(16) rem(32) rem(32);

        &:nth-child(1) {
            border-bottom: 2px solid var(--violet);
        }

        &:nth-child(2) {
            border-top: 2px solid var(--violet);
        }

        &:nth-child(3),
        &:nth-child(4) {
            border-left: 4px solid var(--violet);
        }

        &:nth-child(3) {
            border-bottom: 2px solid var(--violet);
        }

        &:nth-child(4) {
            border-top: 2px solid var(--violet);
        }

        .title {
            width: 80%;
            font-weight: 700;
        }

        img {
            width: rem(100);
            margin: rem(32) auto;
            display: block;
            object-fit: contain;

            &.wide {
                width: rem(126);
            }
        }

        .bullets {
            width: 100%;
            text-align: center;

            .bullet {
                width: 100%;
                margin: rem(24) 0;

                .icon-wrap {
                    width: rem(16);
                    height: rem(20);
                    margin: 0 auto;
                    position: relative;

                    .icon {
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }

                .text {
                    margin-top: rem(12);

                    &.dark {
                        color: var(--violet);
                    }
                }
            }
        }
    }

    @media (--viewport-tablet) {
        width: 100%;
        max-width: unset;
        max-height: unset;
        margin: rem-mobile(32) auto;
        padding: 0 var(--offset-x);
        display: block;

        .item {
            width: 100%;
            padding: rem-mobile(32) 0;
            border-top: 2px solid var(--violet);
            border-bottom: 2px solid var(--violet);
            border-left: none !important;
            border-right: none !important;

            &:nth-child(1) {
                border-top: none;
            }

            &:nth-last-child(1) {
                border-bottom: none;
            }

            .title {
                width: 100%;
            }

            img {
                width: rem-mobile(100);
                margin: rem-mobile(24) auto;

                &.wide {
                    width: rem-mobile(126);
                }
            }

            .bullets {
                .bullet {
                    margin: rem-mobile(24) 0;

                    .icon-wrap {
                        width: rem-mobile(24);
                        height: rem-mobile(22);
                    }

                    .text {
                        margin-top: rem-mobile(14);
                    }
                }
            }
        }
    }
}
</style>
